<template>
	<div class="home">
		<div class="addTime">
			<button @click="showPopup">添加</button>
		</div>
		<van-popup v-model:show="show">
			<van-form @submit="onSubmit" class="pass-form">
				<van-field label="时间段" label-width="5rem" size="large" clearable v-model="time" name="time"
					placeholder="时间段格式08:00-11:00" :rules="[{ required:true, message: '时间段格式08:00-11:00' }]" />
				<van-field label="数量" size="large" label-width="5rem" clearable v-model="num" name="num"
					placeholder="请输入数量" :rules="[{ required:true, message: '请输入数量' }]" />
				<div style="margin-top:2rem;">
					<button class="addBtn" @click="addList">添加</button>
				</div>
			</van-form>
		</van-popup>
		<van>
			<van-list v-model="isUpLoading" :finished="upFinished" :immediate-check="false" :offset="10"
				finished-text="我是有底线的" @load="onLoadList" style="padding:15px 15px 50px 15px;height: 100%;" v-if="goodsList.length != 0">
				<div v-for="(item,index) in goodsList" :key="index" class="goods-item">
					<van-swipe-cell>
						<div>
							<van-cell :border="false" title="时间段" :value="item.time_quantum" />
							<van-cell :border="false" title="数量" :value="item.amount" />
						</div>
						<template #right>
							<van-button square type="danger" text="删除" @click="delList(item.id)" />
							<van-button square type="primary" text="编辑" @click="edit(item.id)" />
						</template>
					</van-swipe-cell>
				</div>

			</van-list>
			<van-empty description="还没有!" v-else />
		</van>
		<van-popup v-model:show="shows">
			<van-form @submit="onSubmit" class="pass-form">
				<van-field label="时间段" label-width="5rem" size="large" clearable v-model="timeD.time_quantum"
					name="time_quantum" placeholder="时间段格式08:00-11:00"
					:rules="[{ required:true, message: '时间段格式08:00-11:00' }]" />
				<van-field label="数量" size="large" label-width="5rem" clearable v-model="timeD.amount" name="amount"
					placeholder="请输入数量" :rules="[{ required:true, message: '请输入数量' }]" />
				<div style="margin-top:2rem;">
					<button class="addBtn" @click="editList">编辑</button>
				</div>
			</van-form>
		</van-popup>
	</div>
</template>
<script>
	import {
		index
	} from '@/api'
	import {
		toUrl
	} from '@/utils/tools'
	import {
		appointTime,
		addTime,
		delTime,
		detailTime,
		editTime
	} from '@/api/user'
	import {
		ref
	} from 'vue';
	import {
		Toast
	} from 'vant';
	export default {
		setup() {
			const show = ref(false);
			const shows = ref(false);
			const time = ref('');
			const num = ref('');
			const showPopup = () => {
				show.value = true;
			};
			return {
				show,
				showPopup,
				time,
				num,
				shows,
			};
		},
		data() {
			return {
				shopType: '',
				nullTip: '空空如也~',
				limit: 10, // 每页条数
				page: 1, // 页码
				type: 1,
				goodsList: [], // 请求数据
				isDownLoading: false, // 下拉刷新
				isUpLoading: false, // 上拉加载
				upFinished: false, // 上拉加载完毕
				offset: 100, // 滚动条与底部距离小于 offset 时触发load事件
				timeD: ''
			}
		},
		mounted() {
			// this.getShopType()
			this.getShopList()
		},
		methods: {
			getShopList() {
				this.$toast.loading({
					message: '加载中...',
					forbidClick: true,
				});
				appointTime({
					page: this.page
				}).then((res) => {
					// console.log(this.page)
					// console.log(res)
					const rows = res.data.data
					// console.log(rows.length)
					if (rows.length === 0) {
						// 加载结束
						this.upFinished = true
						return
					}
					if (rows.length < this.limit) {
						// 最后一页不足10条的情况
						this.upFinished = true
					}
					// 处理数据
					if (this.page === 1) {
						this.goodsList = rows
					} else {
						this.goodsList = this.goodsList.concat(rows)
					}
					// console.log(this.goodsList)
					this.$toast.clear()
				})
			},
			// 添加时间段
			addList() {
				addTime({
					time_quantum: this.time,
					amount: this.num
				}).then((res) => {
					this.show = false
					Toast.success(res.msg);
					// console.log(res)
					this.getShopList();
				})
			},
			// 删除
			delList(id) {
				delTime({
					id: id,
				}).then((res) => {
					Toast.success(res.msg);
					// console.log(res)
					this.getShopList();
				})
			},
			// 编辑详情
			edit(id) {
				this.shows = true
				detailTime({
					id: id
				}).then((res) => {
					// console.log(res)
					this.timeD = res.data
				})
			},
			// 编辑
			editList() {
				editTime({
					id: this.timeD.id,
					time_quantum: this.timeD.time_quantum,
					amount: this.timeD.amount
				}).then((res) => {
					// console.log(res)
					Toast.success(res.msg);
					this.getShopList();
					this.shows = false
				})
			},
			onDownRefresh() {

			},
			// 上拉加载请求方法
			onLoadList() {
				this.page++
				this.getShopList()
			},
		}
	}
</script>
<style lang="less" scoped>
	@import "~@/assets/style/preset.less";

	.home {
		min-height: calc(100vh);
		.addTime {
			width: 100%;
			height: 40px;

			button {
				float: right;
				margin-right: 20px;
				margin-top: 15px;
				width: 60px;
				text-align: center;
				border: 1px solid #ee0a24;
				border-radius: 10px;
				color: #ee0a24;
			}
		}

		.goods-item {
			width: 100%;
			// height: 100px;
			background-color: #FFFFFF;
			margin-bottom: 10px;
			border-radius: 15px;
		}

		.pass-form {
			width: 18rem;
			padding: 2rem 1rem;
		}

		.addBtn {
			width: 100%;
			height: 35px;
			background-color: #1989fa;
			border: solid 1px #1989fa;
			border-radius: 17px;
			color: #FFFFFF;
		}
	}

	.van-swipe-cell__right {
		height: 100%;
	}

	.van-button--normal {
		height: 100%;
	}
</style>
